import React from 'react';
import cx from 'classnames';

const BlobBorder = ({ isBig, shouldAnimate }) => (
  <svg
    className={cx('blobBorder', {
      bigBlobBorder: isBig,
      animatedBlobBorder: shouldAnimate
    })}
    width="140"
    height="85"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 40 20 Q 50 10 60 20 T 80 20" stroke="red" fill="transparent" />
    <path d="M 80 20 Q 100 12 110 35" stroke="orange" fill="transparent" />
    <path d="M 110 35 Q 112 40 115 40" stroke="green" fill="transparent" />
    <path d="M 115 40 Q 128 55 110 58" stroke="green" fill="transparent" />
    <path d="M 110 58 Q 100 55 90 68" stroke="blue" fill="transparent" />
    <path d="M 90 68 Q 80 74 60 65" stroke="blue" fill="transparent" />
    <path d="M 60 65 Q 50 55 40 55" stroke="pink" fill="transparent" />
    <path d="M 40 55 Q 10 50 40 20" stroke="pink" fill="transparent" />
  </svg>
);

export default BlobBorder;
