import React, { useEffect, useRef, useCallback, useState } from 'react';
import * as THREE from 'three';
import { TweenMax, TimelineMax } from 'gsap';
import { VERTEX_SHADER, FRAGMENT_SHADER } from '../utils/shaders';
import { PLANE_WIDTH_SEGMENTS, PLANE_ASPECT_RATIO } from '../utils/config';
let INTERSECTED;

const TEXTURE_PATH = './catwalkingman.JPG';

export const getResponsivenessMultiplier = () => {
  const width = window.innerWidth;
  if (width > 1366) {
    return 0.5;
  }
  if (width > 480) {
    return 0.6;
  }
  return 0.8;
};

const getVisibleDimensionsAtZDepth = (depth, camera) => {
  // compensate for cameras not positioned at z=0
  const cameraOffset = camera.position.z;
  if (depth < cameraOffset) depth -= cameraOffset;
  else depth += cameraOffset;

  // vertical fov in radians
  const vFOV = (camera.fov * Math.PI) / 180;

  // Math.abs to ensure the result is always positive
  const visibleHeight = 2 * Math.tan(vFOV / 2) * Math.abs(depth);
  const visibleWidth = visibleHeight * camera.aspect;

  return {
    visibleHeight,
    visibleWidth
  };
};

const ThreeJsWrapper = ({ children, onMeshRender }) => {
  const mountRef = useRef();

  if (typeof document === 'undefined') return null;

  const rendererRef = useRef(
    new THREE.WebGLRenderer({
      powerPreference: 'high-performance',
      antialias: true,
      alpha: true
    })
  );
  const cameraRef = useRef(
    new THREE.PerspectiveCamera(
      50, // view angle
      window.innerWidth / window.innerHeight, // aspect
      1, // near
      1000 // far
    )
  );
  const sceneRef = useRef(new THREE.Scene());
  const timeRef = useRef(new THREE.Uniform(0));
  const uvRef = useRef(new THREE.Uniform(new THREE.Vector2(0, 0)));
  const textureLoaderRef = useRef(new THREE.TextureLoader());
  const raycasterRef = useRef(new THREE.Raycaster());
  const planeRef = useRef(null);

  useEffect(() => {
    init();
  }, [mountRef]);

  const init = () => {
    const mount = mountRef.current;
    const renderer = rendererRef.current;
    const camera = cameraRef.current;
    const scene = sceneRef.current;
    const textureLoader = textureLoaderRef.current;

    renderer.domElement.id = 'cloppy-canvas';
    renderer.setClearColor(new THREE.Color(0xeeeeee, 1.0));

    textureLoader.load(TEXTURE_PATH, t => {
      t.wrapT = t.wrapS = THREE.RepeatWrapping;
      t.anisotropy = 0;
      t.magFilter = THREE.LinearFilter;
      t.minFilter = THREE.LinearFilter;

      const { visibleWidth } = getVisibleDimensionsAtZDepth(0, camera);

      const planeMultiplier = getResponsivenessMultiplier();

      const planeWidth = visibleWidth * planeMultiplier;
      const planeHeight = planeWidth * PLANE_ASPECT_RATIO;

      const textureWidth = 2126;
      const textureHeight = 1393;
      const ratio = new THREE.Vector2(
        Math.min(
          planeWidth / planeHeight / (textureWidth / textureHeight),
          1.0
        ),
        Math.min(planeHeight / planeWidth / (textureHeight / textureWidth), 1.0)
      );

      const planeMaterial = new THREE.ShaderMaterial({
        uniforms: {
          hover: { type: 'f', value: 0.0 },
          texture: { type: 't', value: t },
          time: timeRef.current,
          intersect: uvRef.current,
          ratio: { type: 'v2', value: ratio },
          hoverRadius: { type: 'f', value: 0.35 },
          speed: { type: 'f', value: 0.7 },
          amplitude: { type: 'f', value: 2 }
        },
        side: THREE.DoubleSide,
        vertexShader: VERTEX_SHADER,
        fragmentShader: FRAGMENT_SHADER
      });

      const planeGeometry = new THREE.PlaneBufferGeometry(
        planeWidth,
        planeHeight,
        PLANE_WIDTH_SEGMENTS,
        Math.round(PLANE_WIDTH_SEGMENTS * PLANE_ASPECT_RATIO)
      );

      const mesh = new THREE.Mesh(planeGeometry, planeMaterial);
      scene.add(mesh);
      planeRef.current = mesh;
      renderer.render(scene, camera);
    });

    camera.position.z = 50;

    scene.background = new THREE.Color('#e5dfd2');
    // set up renderer
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    // set up scene
    onMeshRender();
    mount.appendChild(renderer.domElement);

    mount.addEventListener('mousemove', handleMousemove);
    // mount.addEventListener('touchstart', handleTouchmove);
    // mount.addEventListener('touchmove', handleTouchmove);
    // mount.addEventListener('touchend', handleTouchmove);
    window.addEventListener('resize', handleResize);
    return () => {
      // mount.removeEventListener('touchstart', handleTouchmove);
      // mount.removeEventListener('touchmove', handleTouchmove);
      // mount.removeEventListener('touchend', handleTouchmove);
      mount.removeEventListener('mousemove', handleMousemove);
      window.removeEventListener('resize', handleResize);
    };
  };

  // TODO: debounce things
  const handleResize = useCallback(e => {
    const mount = mountRef.current;
    const camera = cameraRef.current;
    const renderer = rendererRef.current;
    const scene = sceneRef.current;
    const plane = planeRef.current;
    const { innerWidth, innerHeight } = window;

    renderer.setSize(innerWidth, innerHeight);
    // Even if you set the aspect it doesn't do anything
    camera.aspect = innerWidth / innerHeight;
    // until you update the projection matrix!
    // Call this after making changes to most of the properties
    camera.updateProjectionMatrix();
    // Remove and recreate texture to take up new size
    scene.remove(plane);
    mount && init();
  }, []);

  const handleTouchmove = e => {
    const touch = e.changedTouches[0];
    handleMousemove(touch);
  };

  const handleMousemove = useCallback(e => {
    // code to get normalized device coordinates
    const { clientX, clientY } = e;
    const mousePosition = {
      x: (clientX / window.innerWidth) * 2 - 1,
      y: -(clientY / window.innerHeight) * 2 + 1
    };
    updateIntersected(mousePosition);
  }, []);

  const updateIntersected = mouse => {
    const plane = planeRef.current;
    if (!plane) return;
    const uv = uvRef.current;
    const raycaster = raycasterRef.current;
    const camera = cameraRef.current;
    // raycaster code from the THREE.js docs
    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObject(plane, false);

    if (intersects.length > 0) {
      document.body.style.cursor = 'pointer'; // manually set our cursor style to reflect the hover state

      const intersectedPlane = intersects[0].object;

      if (INTERSECTED === intersectedPlane) {
        uv.value.x = intersects[0].uv.x;
        uv.value.y = intersects[0].uv.y;

        const { x = 0, y = 0 } = mouse;

        TweenMax.to(intersectedPlane.position, 0.35, {
          x: x,
          y: y
        });
      } else if (INTERSECTED !== intersectedPlane) {
        INTERSECTED = intersectedPlane;

        new TimelineMax()
          .to(intersectedPlane.material.uniforms.hover, 0.35, { value: 1.0 }, 0)
          .to(intersectedPlane.scale, 0.25, { x: 1.05, y: 1.05 }, 0);
      }
    } else {
      // no intersections
      document.body.style.cursor = 'auto';
      if (INTERSECTED) {
        new TimelineMax()
          .to(INTERSECTED.position, 0.35, { x: 0, y: 0 }, 0)
          .to(INTERSECTED.scale, 0.35, { x: 1, y: 1 }, 0)
          .to(INTERSECTED.material.uniforms.hover, 0.35, { value: 0.0 }, 0);

        INTERSECTED = null;
      }
    }

    const renderer = rendererRef.current;
    const scene = sceneRef.current;
    timeRef.current.value += 0.05 % 1;
    renderer.render(scene, camera);
  };
  return (
    <div ref={mountRef} className="flex">
      {children}
    </div>
  );
};

export default ThreeJsWrapper;
