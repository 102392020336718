import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import ThreeJsWrapper from "../components/threejsWrapper";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Linky from "../components/linky";
import Description from "../components/description";

const IndexPage = () => {
  const [meshRendered, setMeshRendered] = useState(false);
  const [description, toggleDescription] = useState(null);
  const [shouldRenderCanvas, setRenderCanvas] = useState(true);

  useEffect(() => {
    if (!description && !shouldRenderCanvas) setRenderCanvas(true);
  }, [description]);

  const onAnimationEnd = () => {
    if (description && shouldRenderCanvas) {
      setRenderCanvas(false);
    }
  };

  return (
    <Layout onReset={() => description && toggleDescription(null)}>
      <SEO
        title="Chloe"
        description="Chloe is a web developer. She currently works as a frontend engineer at Elevio in Melbourne, Australia."
      />
      {shouldRenderCanvas && !meshRendered && (
        <div className="flex items-center text-xs uppercase">
          loading 3d catman...
        </div>
      )}
      {shouldRenderCanvas && (
        <div
          style={{
            animation: `${!description ? "0.8s fadeIn" : "0.8s fadeOut"}`,
          }}
          onAnimationEnd={onAnimationEnd}
        >
          <ThreeJsWrapper onMeshRender={() => setMeshRendered(true)} />
        </div>
      )}
      {!shouldRenderCanvas && (
        <Description
          toggleDescription={toggleDescription}
          showDescriptionText={!shouldRenderCanvas}
        />
      )}
      <div
        style={{
          animation: description && "1s fadeOut",
        }}
      >
        {shouldRenderCanvas && (
          <Linky
            type="info"
            text="INFO"
            onLinkClick={toggleDescription}
            isToggled={description}
          />
        )}
      </div>
      <Link to="/blog/page/1">
        <Linky type="blog" text="BLOG" isToggled={description} />
      </Link>
    </Layout>
  );
};

export default IndexPage;
