import React, { useState } from 'react';
import cx from 'classnames';
import BlobBorder from './blobBorder';

export const LinkText = ({ text }) =>
  [...text].map((t, i) => (
    <p
      key={`${t}-${i}`}
      className={cx({
        'text-green': i % 3 === 0,
        'text-orange': i % 3 === 1,
        'text-red': i % 3 === 2,
        'text-blue': i % 4 === 0
      })}
    >
      {t}
    </p>
  ));

const Linky = ({ text, type, onLinkClick, isToggled }) => {
  const [displayBorder, setDisplayBorder] = useState(false);

  return (
    <div
      className={cx('cursor-pointer tracking-wide', {
        blog: type === 'blog',
        info: type === 'info',
        zoomedInfoLink: type === 'back' && type === 'info',
        movedBlogLink: isToggled && type === 'blog',
        'text-xs': type === 'back',
        'text-lg': type !== 'back'
        // 'tracking-wide': type === 'back'
      })}
      onMouseEnter={() => setDisplayBorder(true)}
      onMouseLeave={() => setDisplayBorder(isToggled && type === 'info')}
      onClick={() => onLinkClick && onLinkClick(type)}
    >
      <div
        className={cx('flex', {
          pulseText: displayBorder && !isToggled
        })}
      >
        <LinkText text={text} />
        {displayBorder && <BlobBorder shouldAnimate={!isToggled} />}
      </div>
    </div>
  );
};

export default Linky;
