import React from "react";
import Socials from "../components/socials";
import { LinkText } from "../components/linky";

const Back = ({ toggleDescription }) => {
  return (
    <div className="cursor-pointer relative backLink">
      <svg
        viewBox="40 0 60 200"
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        fill="none"
        stroke="#3455db"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        onClick={() => toggleDescription(null)}
        style={{
          position: "absolute",
          left: "-80px",
        }}
      >
        <path d="M 100 0 Q 0 100, 100 200"></path>
        <text
          fontSize="0.8em"
          strokeWidth="0.8"
          x="60"
          y="80"
          className="uppercase"
        >
          Back
        </text>
        <script xmlns="" />
        <text
          fontSize="0.8em"
          strokeWidth="0.8"
          x="65"
          y="100"
          className="uppercase"
        >
          to
        </text>
        <text
          fontSize="0.8em"
          strokeWidth="0.8"
          x="55"
          y="120"
          className="uppercase"
        >
          catMAN
        </text>
      </svg>
    </div>
  );
};

const Description = ({ toggleDescription }) => (
  <div className="description content text-xs font-body flex-col m-16">
    {/* <h4 className="uppercase text-green tracking-wide">INFO</h4> */}
    <div className="flex text-lg">
      <LinkText text="INFO" />
    </div>
    <div className="flex">
      <Back toggleDescription={toggleDescription} />
      <div>
        <p>Hi, I'm Chloe. I like making things that inspire and inform.</p>
        <p>I'm currently a developer based in London.</p>
      </div>
    </div>
    <Socials colour="blue" />
  </div>
);

export default Description;
